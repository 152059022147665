import styled from 'styled-components';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Caption } from '@components/styles/Typography';

const MenuItem = ({
    color,
    count,
    homePageHeader,
    slug,
    name,
    sort,
    isSub,
    active,
    onHover,
    setActiveParent,
}) => {
    const router = useRouter();
    const { category } = router.query;

    const slugWithQueries = () => {
        if (sort !== 'newProduct' && sort !== undefined) {
            return `/${slug}`;
        }
        return router.locale !== 'uk'
            ? `/${router.locale}/${slug}`
            : `/${slug}`;
    };

    const isSelected =
        active ||
        (slug.includes(category) && !isSub) ||
        slugWithQueries() === `/${router.locale}${router.asPath}`;
    return (
        <MenuItemLink
            href={slugWithQueries()}
            onMouseEnter={onHover}
            onClick={setActiveParent}
            passHref
        >
            <MenuItemTitle color={color} selected={isSelected} isSub={isSub}>
                {slug === 'radiant-romance' ? (
                    <img
                        src="/radiant-romance.png"
                        alt="radiant-romance"
                        width="99px"
                        style={{ objectFit: 'cover' }}
                    />
                ) : (
                    <div>
                        {isSelected ? (
                            <MainTitle isSub={isSub}>{name}</MainTitle>
                        ) : (
                            <Title isSub={isSub} color={color && color}>
                                {name}
                            </Title>
                        )}
                    </div>
                )}
                <MenuItemCount
                    homePageHeader={homePageHeader}
                    isSub={isSub}
                    isSelected={isSelected}
                >
                    {count}
                </MenuItemCount>
            </MenuItemTitle>
        </MenuItemLink>
    );
};

const MenuItemLink = styled(Link)`
    @media (max-width: 1024px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
`;
const MenuItemCount = styled(Caption)`
    position: absolute;
    top: -8px;
    right: -20px;
    transition: all 1s ease;
    display: ${({ isSelected }) => (isSelected ? 'block' : 'none')};
    border-bottom: none !important;
    color: ${({ theme, isSub, isSelected }) =>
        isSub
            ? isSelected
                ? theme.colors.primary
                : theme.colors.bgGray50
            : theme.colors.primary};

    &:hover ${MenuItemLink} {
        display: block;
    }
    @media (max-width: 1024px) {
        display: none;
        top: 8px;
        right: -15px;
    }
`;

const MainTitle = styled.h1`
    font-family: var(--futura-font);
    font-weight: ${({ theme, bolder }) =>
        bolder ? 900 : theme.fontWeights.regular};

    font-size: ${({ theme }) => theme.sizes.m};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

const Title = styled.h2`
    font-family: var(--futura-font);
    font-weight: ${({ theme, bolder }) =>
        bolder ? 900 : theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.sizes.m};
    color: ${({ theme, color }) =>
        color ? theme.colors[color] : theme.colors.primary};
    line-height: 22px;
    letter-spacing: 1px;
    text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

const MenuItemTitle = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border-bottom: 2px solid transparent;
    white-space: nowrap;

    img {
        padding-bottom: 8px;
    }

    :first-child {
        @media (min-width: 1025px) {
            border-bottom: ${({ selected, isSub }) =>
                selected && !isSub ? '1px solid #000' : 'none'};
        }
    }
    span {
        padding-bottom: 1px;
        color: ${({ theme, isSub, isSelected }) =>
            isSub
                ? isSelected
                    ? theme.colors.primary
                    : theme.colors.bgGray50
                : theme.colors.primary};

        letter-spacing: ${({ isSub, selected }) =>
            isSub ? (selected ? '1px' : 'normal') : selected ? '2px' : '1px'};

        @media (max-width: 1024px) {
            color: ${({ theme }) => theme.colors.primary};
            font-size: ${({ isSub }) => (isSub ? '10px' : '12px')};
            letter-spacing: ${({ selected }) => (selected ? '2px' : '1px')};
        }
    }
    @media (min-width: 1025px) {
        :hover {
            ${MenuItemCount} {
                display: block;
            }
        }
    }
    @media (max-width: 1024px) {
        padding: ${({ padding }) => padding || '8px 0'};
        margin: 0;
    }
`;
export default MenuItem;
