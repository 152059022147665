import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@hooks';
import { Caption } from '@components/styles/Typography';

const messages = [
    {
        en: (
            <>
                <Caption>WORLDWIDE</Caption> free Shipping -{' '}
                <Caption> TAXES</Caption> and
                <Caption> CUSTOMS </Caption> included -{' '}
                <Caption> SECURE</Caption> payment{' '}
            </>
        ),
        tr: <Caption>Tüm Ürünlerde Ücretsiz Kargo </Caption>,
    },
    {
        en: <Caption>10% Off Your First Order</Caption>,
        tr: <Caption>İlk Alışverişte %10 İndirim </Caption>,
    },
];

const Topbar = () => {
    const [messageIndex, setMessageIndex] = useState(0);
    const { locale } = useTranslation();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
        }, 3000);
        return () => clearInterval(intervalId);
    }, []);

    const currentMessage = messages[messageIndex][locale];

    return (
        <TopbarWrapper>
            <TextContainer>
                <Caption color="secondary">{currentMessage}</Caption>
            </TextContainer>
        </TopbarWrapper>
    );
};

const TopbarWrapper = styled.div`
    width: 100%;
    height: 30px;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.secondary};
    position: fixed;
    top: 0;
    z-index: 888;
`;

const TextContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    overflow: hidden;
    transition: transform 0.5s ease-in;
    transform: translateY(0);

    &:nth-child(even) {
        transform: translateY(-100%);
    }

    span {
        color: ${({ theme }) => theme.colors.secondary};
    }
`;

export default Topbar;
