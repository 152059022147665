import { sha256 } from 'js-sha256';

export const GTM_ID = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

export const pageview = (url) => {
    // window.ttq.track('PageView');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'pageview',
        page: url,
    });
};

export const sendRegionToGA = (region) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        region,
    });
};

export const sendParentCategory = (category) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        category,
    });
};

export const signUpEvent = ({ smsConstent, emailConstent }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        event: 'sign_up',
        clear_constent_sms: smsConstent,
        clear_constent_email: emailConstent,
    });
};

export const addToFavouritesEvent = ({
    product,
    currency,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'add_to_wishlist',

        ecommerce: {
            currency, // UK tarafı için GBP
            value: Number(product?.discounted_price[currency]),
            email: hashedEmail,
            phone,
            items: [
                {
                    item_id: product?.id.toString(), // Ürün ID'si
                    item_name: product.name.tr, // Ürün adı
                    affiliation: 'Keikei Web Store', //
                    discount: product?.discounted_rate, // İndirim miktarı
                    index: product?.listing_point, // Ürün sıralaması
                    item_brand: 'Keikei', // Ürün markası
                    item_list_id: product?.collection_name, // Ürün liste ID'si
                    item_list_name: product?.collection_name, // Ürün liste adı
                    price: Number(product.price[currency]), // Ürün fiyatı
                    quantity: 1, // Ürün adedi
                },
            ],
        },
    });
};

export const addToBasketEvent = ({
    product,
    currency,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'eec_add_to_basket',
        eventCategory: 'Enhanced Ecommerce',
        eventAction: 'Add To Basket ',
        eventLabel: 'Add to Basket',
        ecommerce: {
            currencyCode: currency,
            email: hashedEmail,
            phone,
            add: {
                actionField: { list: product?.category[0]?.name?.tr }, // 'add' actionFieldObject measures.
                products: [
                    {
                        name: product?.name.tr,
                        id: product?.id,
                        price: product?.discounted_price[currency],
                        brand: 'KeiKei',
                        category: product?.colour.name.tr,
                        variant: 'beyaz',
                        quantity: 1,
                    },
                ],
            },
        },
    });
};

export const addToCart = ({
    product,
    currency,
    data,
    email = '',
    phone = '',
}) => {
    // window.ttq.track('AddToCart', {
    //     contents: [
    //         {
    //             content_id: product.id.toString(),
    //             content_name: product.name.tr,
    //             quantity: 1,
    //             price: Number(product.price.currency),
    //         },
    //     ],
    //     content_type: 'product',
    //     value: Number(product.price.currency),
    //     currency,
    // });

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer = window.dataLayer || [];

    const variant = data.data.products.slice(-1)[0];
    const hashedEmail = sha256(email);
    window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
            currency,
            value: Number(product.price[currency]),
            email: hashedEmail,
            phone,
            items: [
                {
                    item_id: product.id.toString(),
                    item_name: product.name.tr,
                    affiliation: 'Keikei Web Store',
                    discount:
                        Number(product.price.TRY) -
                        Number(product.discounted_price.TRY),
                    index: 0,
                    item_brand: 'Keikei',
                    item_category: product.category?.[0]?.name.tr,
                    item_category2: product.category?.[1]?.name.tr,
                    item_category3: product.category?.[2]?.name.tr,
                    item_category4: product.category?.[3]?.name.tr,
                    item_category5: product.category?.[4]?.name.tr,
                    item_list_id:
                        product?.collection_name ||
                        product.category[0]?.name.tr ||
                        '',
                    item_list_name:
                        product?.collection_name ||
                        product.category[0]?.name.tr ||
                        '',
                    item_variant: product?.colour?.name?.tr || '',
                    location_id: '',
                    price: Number(product.price.TRY),
                    quantity: 1,
                    item_stock: variant.stock,
                    item_size: variant.size_label,
                    stock_count_36: '',
                    stock_count_38: '',
                    stock_count_40: '',
                    total_stock_count: '',
                },
            ],
        },
    });
};

export const viewItemList = ({ category, items, email = '', phone = '' }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'view_item_list',
        ecommerce: {
            item_list_id: category,
            item_list_name: category,
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: category,
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id: item?.collection_name || category || '',
                item_list_name: item?.collection_name || category || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: 1,
                item_stock: '',
                item_size: '',
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: item.stock,
            })),
        },
    });
};

export const selectItem = ({ category, items, email = '', phone = '' }) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'select_item',
        ecommerce: {
            item_list_id: category,
            item_list_name: category,
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: category,
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id: item?.collection_name || category || '',
                item_list_name: item?.collection_name || category || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: 1,
                item_stock: '',
                item_size: '',
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: item.stock,
            })),
        },
    });
};

export const viewItem = ({
    items,
    price,
    currency,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);
    // window.ttq.track('ViewContent', {
    //     contents: [
    //         {
    //             content_id: items[0].id.toString(),
    //             content_name: items[0].name.tr,
    //             quantity: 1,
    //             price: Number(items[0].price.currency),
    //         },
    //     ],
    //     content_type: 'product',
    //     value: Number(items[0].price.currency),
    //     currency,
    // });
    window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
            currency,
            value: Number(price[currency]),
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: item.category?.[0]?.name.tr,
                item_category2: item.category?.[1]?.name.tr,
                item_category3: item.category?.[2]?.name.tr,
                item_category4: item.category?.[3]?.name.tr,
                item_category5: item.category?.[4]?.name.tr,
                item_list_id:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_list_name:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: 1,
                item_stock: item.stock,
                item_size: '',
                stock_count_36: item.sizes[0]?.stock,
                stock_count_38: item.sizes[1]?.stock,
                stock_count_40: item.sizes[2]?.stock,
                total_stock_count: item.stock,
            })),
        },
    });
};

export const viewCart = ({
    items,
    currency,
    total,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    const totalStockCount = items.reduce((acc, item) => acc + item.stock, 0);
    window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
            currency,
            value: Number(total[currency]),
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: item.category?.[0]?.name.tr,
                item_category2: item.category?.[1]?.name.tr,
                item_category3: item.category?.[2]?.name.tr,
                item_category4: item.category?.[3]?.name.tr,
                item_category5: item.category?.[4]?.name.tr,
                item_list_id:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_list_name:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: item.quantity,
                item_stock: item.stock,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: totalStockCount,
            })),
        },
    });
};

export const beginCheckout = ({
    items,
    currency,
    total,
    coupon,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const totalStockCount = items.reduce((acc, item) => acc + item.stock, 0);
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
            currency,
            coupon,
            value: total[currency],
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount:
                    Number(item.price.TRY) - Number(item.discounted_price.TRY),
                index: i,
                item_brand: 'Keikei',
                item_category: item?.category?.name?.tr,
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id:
                    item?.collection_name || item?.category?.name?.tr || '',
                item_list_name:
                    item?.collection_name || item?.category?.name?.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: item.quantity,
                item_stock: item.stock,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: totalStockCount,
            })),
        },
    });
};

export const addShippingInfo = ({
    items,
    currency,
    total,
    coupon,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const totalStockCount = items.reduce((acc, item) => acc + item.stock, 0);
    const hashedEmail = sha256(email);
    window.dataLayer.push({
        event: 'add_shipping_info',
        ecommerce: {
            currency,
            value: Number(total[currency]),
            coupon,
            shipping_tier: 'Yurtiçi',
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.model_id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: item?.category?.name?.tr,
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id:
                    item?.collection_name || item?.category?.name?.tr || '',
                item_list_name:
                    item?.collection_name || item?.category?.name?.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: item.quantity,
                item_stock: item.stock,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: totalStockCount,
            })),
        },
    });
};

export const addPaymentInfo = ({
    items,
    currency,
    total,
    coupon,
    paymentType,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const totalStockCount = items.reduce((acc, item) => acc + item.stock, 0);
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'add_payment_info',
        ecommerce: {
            currency,
            value: total,
            coupon,
            payment_type: paymentType,
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.model_id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: item?.category?.name?.tr,
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id:
                    item?.collection_name || item.category?.name?.tr || '',
                item_list_name:
                    item?.collection_name || item.category?.name?.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: item.quantity,
                item_stock: item.stock,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: totalStockCount,
            })),
        },
    });
};

export const purchase = ({
    items,
    total,
    transactionID,
    currency,
    coupon,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const totalStockCount = items.reduce((acc, item) => acc + item.stock, 0);
    const hashedEmail = sha256(email);
    // window.ttq.track('CompletePayment', {
    //     contents: items.map((item) => ({
    //         content_id: item.id.toString(),
    //         content_name: item.name.tr,
    //         quantity: item.quantity,
    //         price: Number(item.price.currency),
    //     })),
    //     content_type: 'product',
    //     value: Number(items[0].price.currency),
    //     currency,
    // });
    window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
            transaction_id: transactionID,
            value: total,
            tax: 0,
            shipping: 0,
            currency,
            coupon,
            email,
            hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.model_id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_category: item.category?.[0]?.name.tr,
                item_category2: item.category?.[1]?.name.tr,
                item_category3: item.category?.[2]?.name.tr,
                item_category4: item.category?.[3]?.name.tr,
                item_category5: item.category?.[4]?.name.tr,
                item_list_id:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_list_name:
                    item?.collection_name || item.category?.[0]?.name.tr || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: item.quantity,
                item_stock: item.stock - item.quantity,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: totalStockCount - item.quantity,
            })),
        },
    });
};
export const removeFromBasket = ({
    items,
    currency,
    quantity,
    email = '',
    phone = '',
}) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'remove_from_cart',
        ecommerce: {
            currency,
            value: Number(items[0].price[currency]),
            email: hashedEmail,
            phone,
            items: items.map((item, i) => ({
                item_id: item.id.toString(),
                item_name: item.name.tr,
                affiliation: 'Keikei Web Store',
                discount: item.price.TRY - item.discounted_price.TRY,
                index: i,
                item_brand: 'Keikei',
                item_category: '',
                item_category2: '',
                item_category3: '',
                item_category4: '',
                item_category5: '',
                item_list_id: item?.collection_name || '',
                item_list_name: item?.collection_name || '',
                item_variant: item?.colour?.name?.tr || '',
                location_id: '',
                price: Number(item.price.TRY),
                quantity: quantity || item.quantity,
                item_stock: item.stock,
                item_size: item.size_label,
                stock_count_36: '',
                stock_count_38: '',
                stock_count_40: '',
                total_stock_count: '',
            })),
        },
    });
};

export const viewPromotion = (promotion) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const email = promotion.email || '';
    const hashedEmail = sha256(email);

    window.dataLayer.push({
        event: 'view_promotion',
        ecommerce: {
            creative_name: promotion.creative,
            creative_slot: promotion.slot,
            promotion_id: promotion.id,
            promotion_name: promotion.name,
            phone: promotion.phone || '',
            email: hashedEmail,
        },
    });
};

export const selectPromotion = (promotion) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    const email = promotion.email || '';
    const hashedEmail = sha256(email);
    window.dataLayer.push({
        event: 'select_promotion',
        ecommerce: {
            creative_name: promotion.creative,
            creative_slot: promotion.slot,
            promotion_id: promotion.id,
            promotion_name: promotion.name,
            phone: promotion.phone || '',
            email: hashedEmail,
        },
    });
};

export const paymentError = (error) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
        event: 'payment_error',
        error,
    });
};
